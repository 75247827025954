import Link from "next/link";
import Image from "next/image";
import {type} from "os";
import {Product} from "../../models/product";

type ICardData = {
  product: Product,
  clickable?: boolean,
  moreCls?: string,
  detailPage?: boolean
  
}

const ProductCard = ({product, clickable = true, moreCls = "", detailPage = false}: ICardData) => {
  
  return (
    /*TODO clickable thing needs improvement */
    <Link href={`/products/${product.slug}`}
          className={`a-decoration-free-link ${!clickable ? "disabled" : ""} ${moreCls}`}>
      <div className={'card product-card  h-100 '}>
        <Image className={"card-img-top2 img-h-auto2"} src={product.imageUrl}
          // priority={true}
          //      width={!detailPage ? 194 : 524}
          //      height={!detailPage ? 129 : 350}
               width={0}
               height={0}
               sizes={"100vw"}
               style={{height: "auto", width: "100%"}}
               alt={product.name}/>
        <div className="card-body d-flex flex-column text-center ">
          <span className="quantity text-muted a-fs--2">{product.quantity}</span>
          <span className="name">{product.name}</span>
          <span className="price a-fs-2 fw-bold">{product.price}</span>
          <span className="ppu a-fs--1 text-muted">{product.pricePerUnit}</span>
        </div>
      </div>
    
    </Link>
  
  )
}
export default ProductCard