import {Inter} from '@next/font/google'
import ProductCard from "../components/products/ProductCard";
import {apiRoute, productColClass} from "../utils/dynamicStrings";
import {routes} from "../utils/routes";
import {IProduct, IProducts} from "../models/product";
import React from "react";
import {str} from "../utils/str";


const inter = Inter({subsets: ['latin']})

export default function Home({products, categories}) {
  return (
    <>
      <HomeProducts products={products.data}/>
    </>
  )
}


const HomeProducts = ({products} : IProducts) => {
  return (
    <>
      <h3>{str.home.products}</h3>
      <div className="products-wrap row ">
        {
          products.map(p => <ProductCol key={p.id} product={p}/>)
        }
      </div>
    </>
  )
}

const ProductCol = ({product} : IProduct) => {
  return (
    <div className={productColClass()}>
      <ProductCard product={product}/>
    </div>
  )
}



export async function getServerSideProps(context) {
  const res1 = await fetch(apiRoute(routes.products.url), {
    headers: {
      "Accept" : 'application/json',
      "Content-Type" : 'application/json',
      'User-Agent': '*',
    }
  })
  const products = await res1.json();
  return {
    props: {products}
  }
}

